// Packages
import React from "react";
import { graphql } from "gatsby";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import Button from "~components/common/Button";
import SubTitle from "~components/typography/SubTitle";
import H1 from "~components/typography/H2";
import P from "~components/typography/P";
import Hero from "~components/sections/Hero";
import Ul from "~components/typography/Ul";
import H3 from "~components/typography/H3";
import Alert from "~components/common/Alert";
import Container from "~components/common/Container";
import CtaBox from "~components/common/CtaBox";
import Grid from "~components/common/Grid";
import TestmonialSingle from "~components/sections/TestmonialSingle";
import H2 from "~components/typography/H2";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	imageHeader: IGatsbyImageData;
	profileImage: IGatsbyImageData;
	imageTestmonial: IGatsbyImageData;
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Business Coaching"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Hero image={data.imageHeader} alt="Coaching mit Daniel Thiel">
				<SubTitle className="mb-2">Business Coaching für Unternehmer & Selbstständige</SubTitle>
				<H1 display className="mb-4">
					Transformiere dein Business mit maßgeschneidertem 1:1 Coaching
				</H1>
				<P>
					Systemisches Business Coaching speziell für Unternehmer und Selbstständige für mehr Wachstum und Freiheit:
				</P>
				<Ul
					items={[
						<>
							<b>Individuelles 1:1 Coaching:</b>
							<br />
							Maßgeschneiderte Unterstützung, um deinen nachhaltigen Erfolg zu sichern. Entwickle Strategien, die auf
							deine spezifischen Bedürfnisse und Ziele ausgerichtet sind.
						</>,
						<>
							<b>Hypnose-Coaching:</b>
							<br />
							Überwinde tief verwurzelte Glaubenssätze, die dich und dein Unternehmen zurückhalten. Durch die Anwendung
							von Hypnose-Techniken lösen wir Blockaden direkt an ihrem Ursprung auf und schaffen Raum für neue
							Perspektiven.
						</>,
						<>
							<b>Neuprogrammierung für Erfolg:</b>
							<br />
							Setze neue Standards für Erfolg und Wachstum in deinem Geschäft. Wir nutzen systemisches Coaching, um
							Verhaltensweisen und Denkmuster auf nachhaltiges Wachstum und erfolgreiche Geschäftsergebnisse
							auszurichten.
						</>,
						<>
							<b>Aktivierungs-Coaching:</b>
							<br />
							Komme ins Handeln statt zu planen. Mit unserem Aktivierungs-Coaching unterstützen wir dich, sofort mit der
							Umsetzung zu beginnen und Prokrastination zu überwinden. Gemeinsam mit anderen Experten für Aktivierung
							sorgen wir dafür, dass du deine Ziele nicht nur setzt, sondern auch erreichst.
						</>,
						<>
							<b>Expertise und Erfahrung:</b>
							<br />
							Profitiere von meiner umfangreichen Erfahrung und Expertise, die ich bei der Mehr Geschäft Coaching GmbH
							und der Greator GmbH gesammelt habe, mit einem starken Fokus auf Umsatzsteigerung, Wachstum und mentale
							Entwicklung.
						</>,
						<>
							<b>Analyse und Optimierung:</b>
							<br />
							Als Wirtschaftswissenschaftler und Finanzexperte verstehe ich die Zahlen, die dein Geschäft antreiben. Wir
							arbeiten daran, deine Unternehmenskennzahlen zu verbessern und deine Geschäftsprozesse effizienter zu
							gestalten.
						</>,
					]}
					className="mb-8"
					pretty
				></Ul>
				<H3 className="mb-4">Preisgestaltung</H3>
				<P className="mb-10">
					Da jedes Geschäft einzigartig ist, bieten wir flexible Coaching-Pakete an, passend für dich und dein Business.
					Fülle bitte das Kontaktformular aus oder kontaktiere uns direkt, um eine persönliche Beratung zu vereinbaren.
				</P>
				<Button to="/coaching/business/anfragen/" text="Termin vereinbaren" />
			</Hero>
			<Container>
				<SubTitle className="mb-2">Leistungsspektrum</SubTitle>
				<H2 className="mb-4">Unser Business-Coaching</H2>
				<Grid cols={2}>
					<div>
						<P className="mb-4">
							Jeder Mensch, der <span className="font-bold">überdurchschnittliche Erfolge</span> erzielt, hat einen
							Coach. Egal ob Sportler, Manager, Musiker, Politiker oder Unternehmer. Wie ist das bei dir?
						</P>
						<P className="mb-4">
							Alle unsere Probleme, aber auch alle unsere Lösungen befinden sich in unserem{" "}
							<span className="font-bold">Kopf</span>, <span className="font-bold">Herz</span> und{" "}
							<span className="font-bold">Bauch</span>. Was hält Dich wirklich auf?
						</P>
						<P className="mb-4">
							Nicht alles, <span className="font-bold">was zählt</span>, ist <span className="font-bold">messbar</span>,
							und nicht alles, was messbar ist, zählt.
						</P>
						<P className="mb-4">
							In meinem Coaching bekommst Du Klarheit auf das was Du wirklich willst. Gemeinsam finden wir was Dich
							aufhält Dein volles Potential zu entfalten. Komm in mein Coaching. Ich freue mich auf Dich. Der Mensch ist
							ein Mosaik an Bedürfnissen. Wir finden die Lösung für Dein Problem! Lebenskrisen sind hausgemachte, eigene
							Wahrnehmungskrisen, denn die wirklich wichtigen Schlachten im Leben, schlagen wir alleine.
						</P>
						<P className="mb-4">
							Mit unserem System haben wir hunderten von Selbständigen und Unternehmern geholfen ihr volles Potential zu
							entfalten, ihr eigenes Warum zu definieren und eine individuelle Strategie zu nutzen wie man ein
							erfolgreiches und glückliches Leben führt.
						</P>
						<P>Die Antworten sind in dir!</P>
					</div>
					<div>
						<P>Das erwartet Dich in meinem Coaching</P>
						<Ul
							items={[
								"Löse Blockaden … was hält Dich wirklich auf?",
								"Schärfe Dein Mindset …think BIG und fokussiere",
								"Vergangenheit und Zukunft …Freund oder Feind?",
								"Finde Dein Warum … worum geht es dir wirklich?",
								"Erkenne Deine Stolpersteine …Gewohnheiten erkennen",
								"Erkenne Deine Persönlichkeit …Worauf bist Du die Antwort?",
								"Erkenne Deine Stärken … bewusste und unbewusste Kompetenzen",
								"Entwickle Deine eigene Strategie … Handelst Du nach Deinem WARUM?",
							]}
							pretty
							className="mb-10"
						/>
						<P>
							Mein Team ist eine Expertengruppe aus Managern, Psychologen, Business Coaches, spirituellen Trainern,
							Mindset-Trainern für Soldaten und Experten im Unternehmertum. Je nach Bedarf und individueller Situation
							findet ein zusätzlicher Baustein statt um die Lösungen und die erforderlichen Antworten zu finden, damit
							ich das versprechen an Dich einhalten kann. Wir gehen den Weg gemeinsam. Sicher dir einen Platz in meinem
							Coaching und pack es an. Ich freue mich auf Dich.
						</P>
					</div>
				</Grid>
			</Container>

			<Container>
				<Grid cols={2}>
					<Alert
						type="information"
						title="Achtung"
						message="Wir haben pro Jahr eine begrenzte Anzahl an Kunden, die wir in unser Coaching-Programm aufnehmen. Wir nehmen uns für jeden Kunden sehr viel Zeit. Ich bin besessen von der Zielerreichung meiner Kunden. Das schätzen, spüren und genießen meine Kunden an mir und meinem Team."
						className="mb-4"
					/>
					<CtaBox
						title="Investiere in Dich selbst!"
						description="Termin buchen und ein kostenloses 15-minütiges und unverbindliches Gespräch nutzen."
						button={{ text: "Termin vereinbaren", to: "/coaching/anfragen/" }}
					/>
				</Grid>
			</Container>

			<TestmonialSingle
				imageData={data.imageTestmonial}
				quote="Ich benutze zeitlose Philosophien, wissenschaftliche Wahrheiten, spirituelle Erfahrungen und bewährte Taktiken, um Dich zu verbessern. Verstecke Dich nicht hinter Deinem Umständen. Verantworte Dein Hier und Jetzt."
				alt="Daniel Thiel Zitat"
				author="Daniel Thiel"
			/>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageHeader: file(relativePath: { eq: "pages/coaching/coaching_header.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageTestmonial: file(relativePath: { eq: "pages/coaching/coaching_testmonial.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
